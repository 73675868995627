import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import JobDetails from '../Components/Job/Details';
import Viewport from '../Components/Viewport';

import useJobs from '../Hooks/useJobs';

const Details = ({ children, className }) => {
  const { id } = useParams();
  const jobs = useJobs();
  const [job, setJob] = useState();

  useEffect(() => {
    jobs.get(id).then(setJob);
  }, [id, jobs]);

  const title =
    jobs.fetching || !job ? `Job ${id}...` : job.Payload.Title || `Job ${id}`;

  return (
    <Viewport title={title} dashboardBtn logoutBtn className={className}>
      <JobDetails>{children}</JobDetails>
    </Viewport>
  );
};

Details.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

Details.defaultProps = {
  children: null,
  className: ''
};

export default Details;
