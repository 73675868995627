import React, { useCallback, useMemo, useState } from 'react';
import {
  arrayOf,
  number,
  shape,
  string,
} from 'prop-types';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Button } from '@material-ui/core';
import IconDownload from "frontend-common/src/Components/Icon/Download";
import downloadAsFile from "frontend-common/src/utils/downloadAsFile";

const metrics = [
  {
    label: 'Cordax Score',
    value: 'cordax',
  },
  {
    label: 'Chou-Fasman Helix',
    value: 'CFHelix',
  },
  {
    label: 'Chou-Fasman Strand',
    value: 'CFStrand',
  },
  {
    label: 'Kyte-Doolitle Hydrophobicity',
    value: 'KyteDoolitleHydrophobicity',
  },
  {
    label: 'Wimley-White Hydrophobicity',
    value: 'WimleyWhiteHydrophobicity',
  },
  {
    label: 'logD',
    value: 'logD',
  },
  {
    label: 'logP',
    value: 'logP',
  },
];

const valueFormatter = tick => new Intl.NumberFormat('en', {
  maximumFractionDigits: 2,
  maximumSignificantDigits: 4,
  minimumSignificantDigits: 2,
  roundingPriority: 'lessPrecision',
}).format(tick);

// Copied from standalone-cordax/predictor.py
const threshold = 0.6106299122287593;

const DetailsArea = ({ job, profile }) => {
  const [ metric, setMetric ] = useState('cordax');

  const data = useMemo(() => {
    if (metric === 'cordax') {
      return profile.map(entry => ({
        ...entry,
        value: entry.value,
        threshold,
      }));
    }

    return profile.map((entry, idx) => ({
      ...entry,
      value: parseFloat(job?.Processed.Properties[idx]?.[metric]),
    }));
  }, [ job, metric, profile ]);

  const downloadData = useCallback(() => {
    const table = [
      [
        "Amino Acid",
        ...metrics.map(({ label }) => label),
      ].join(','),
      ...profile.map((entry, idx) => [
        entry.aminoacid,
        ...metrics.map(({ value }) =>
          value === "cordax"
            ? entry.value
            : parseFloat(job?.Processed.Properties[idx]?.[value])
        ),
      ].join(',')),
      '',
    ].join('\n');

    downloadAsFile(`${job.JobId}-profile-metrics.csv`, table);
  }, [ job, profile ]);

  return job && (
    <div className="details__area">
      <div className="details__metrics">
        {metrics
          .filter(
            ({ value }) =>
              value === "cordax" || value in job.Processed.Properties[0]
          )
          .map(({ label, value }) => (
            <Button
              key={value}
              className="details__metric"
              color={metric === value ? "primary" : "default"}
              onClick={() => setMetric(value)}
              variant={metric === value ? "contained" : "outlined"}
            >
              {label}
            </Button>
          ))}

        <button
          className="details__metrics-download"
          onClick={downloadData}
          title="Download as CSV"
          type="button"
        >
          <IconDownload />
        </button>
      </div>

      <div className="details__chart">
        <ResponsiveContainer
          height={300}
          width="100%"
          minWidth={`${80 + data.length * 10}px`}
        >
          <ComposedChart
            data={data}
            margin={{
              top: 5,
              right: 5,
              bottom: 5,
              left: 20,
            }}
          >
            <defs>
              <linearGradient id="amyloidFill" x1="0" y1="0" x2="1" y2="0">
                {profile.map((item, idx) => (
                  <stop
                    // eslint-disable-next-line react/no-array-index-key
                    key={`color-stop-${idx}`}
                    offset={`${(idx / (profile.length - 1)) * 100}%`}
                    stopColor={item.value > threshold ? "#3eaea4" : "#c4c4c4"}
                    stopOpacity={item.value > threshold ? 0.45 : 0.275}
                  />
                ))}
              </linearGradient>
              <linearGradient id="amyloidStroke" x1="0" y1="0" x2="1" y2="0">
                {profile.map((item, idx) => (
                  <stop
                    // eslint-disable-next-line react/no-array-index-key
                    key={`color-stop-${idx}`}
                    offset={`${(idx / (profile.length - 1)) * 100}%`}
                    stopColor={item.value > threshold ? "#3eaea4" : "#c4c4c4"}
                    stopOpacity={1}
                  />
                ))}
              </linearGradient>
            </defs>

            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="aminoacid" interval={0} />
            <YAxis
              tickFormatter={valueFormatter}
              ticks={metric === "cordax" && [0, 0.2, 0.4, 0.6, 0.8, 1]}
            >
              <Label
                angle={-90}
                dx={-15}
                position="insideLeft"
                style={{ textAnchor: "middle" }}
                value={metrics.find((entry) => entry.value === metric).label}
              />
            </YAxis>
            <Tooltip formatter={valueFormatter} />

            <Area
              animationDuration={500}
              dataKey="value"
              fill={metric === "cordax" ? "url(#amyloidFill)" : "#3eaea4"}
              fillOpacity={metric === "cordax" ? 1 : 0.45}
              stroke={metric === "cordax" ? "url(#amyloidStroke)" : "#3eaea4"}
              type="monotone"
            />

            {metric === "cordax" && (
              <Line
                animationDuration={500}
                dataKey="threshold"
                dot={false}
                stroke="#3eaea4"
                strokeDasharray="5 5"
                type="monotone"
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

DetailsArea.propTypes = {
  job: shape({
    Processed: shape({
      Properties: arrayOf(shape({
        CFHelix: string,
        CFStrang: string,
        KyteDoolitleHydrophobicity: string,
        logD: string,
        logP: string,
        WimleyWhiteHydrophobicity: string,
      })),
    }),
  }).isRequired,
  profile: arrayOf(shape({
    aminoacid: string,
    value: number,
  })).isRequired,
};

export default DetailsArea;
