import NewJob from 'frontend-common/src/Screens/NewJob';
import React, { useState } from 'react';

import HCaptcha from '@hcaptcha/react-hcaptcha';
import Viewport from 'frontend-common/src/Components/Viewport';
import Amplify from 'frontend-common/src/lib/Amplify';
import useAuth from 'frontend-common/src/Hooks/useAuth';
import SampleSequences from '../Components/SampleSequences';
import newJob from '../content/screens/new_job.json';
import options from '../options';

// The configurator provides us the basic login and sign up screens.
export default () => {
  const { AuthSession } = useAuth();
  const [isAllowed, setIsAllowed] = useState(false);

  return (AuthSession || isAllowed) ? (
    <NewJob title={newJob.title} options={options}>
      <SampleSequences />
    </NewJob>
  ) : (
    <Viewport className='NewJob' title={newJob.title} dashboardBtn logoutBtn>
      <HCaptcha
        sitekey='09d77949-d9d8-4d01-83eb-f08428b35e70'
        onVerify={async (token) => {
          const res = await Amplify.post('verifytoken', {
            token
          });
          const success = res?.success;
          setIsAllowed(success);
        }}
      />
      <SampleSequences />
    </Viewport>
  );
};
