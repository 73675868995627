import React from 'react';
import Markdown from 'react-markdown';

import Home from 'frontend-common/src/Screens/Home';

import landingPage from '../content/screens/landing_page.json';

export default () => (
  <Home logo="../static/media/cordax-logo-color.svg" title={landingPage.title}>
    <Markdown>{landingPage.body}</Markdown>
  </Home>
);
