import React, { memo, useEffect, useState } from 'react';
import { node } from 'prop-types';
import { useParams } from 'react-router-dom';

import { Paper } from '@material-ui/core';

import useJobs from '../../Hooks/useJobs';

import getJobState from '../../utils/getJobState';

const JobDetails = ({ children }) => {
  const { id } = useParams();
  const jobs = useJobs();
  const [ job, setJob ] = useState();

  useEffect(() => {
    jobs.get(id).then(setJob);
  }, [ id, jobs ]);

  if (job === undefined) {
    return null;
  }

  if (!job) {
    return (
      <div>
        { `No job with ID ${id} found.` }
      </div>
    );
  }

  const state = getJobState(job);

  if (![ 'Errored', 'ErroredSeen', 'Finished' ].includes(state)) {
    return (
      <div>
        { `Job ${id} is not finished.` }
      </div>
    );
  }

  return (
    <Paper className="JobDetails__wrapper">
      { children }
    </Paper>
  );
};

JobDetails.propTypes = {
  children: node,
};

JobDetails.defaultProps = {
  children: null,
};

// The configurator provides us the basic login and sign up screens.
export default memo(JobDetails);
