import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IconDownload from "frontend-common/src/Components/Icon/Download";
import IconExternalLink from 'frontend-common/src/Components/Icon/ExternalLink';
import { Link, Typography } from '@material-ui/core';
import Table from 'frontend-common/src/Components/Table';
import downloadAsFile from 'frontend-common/src/utils/downloadAsFile';
import PropTypes from 'prop-types';
import stripTags from 'striptags';
import classnames from 'classnames';

/**
 * @typedef {Object} Protein
 * @property {string} Sequence
 * @property {string} Classification
 * @property {string} TEM Staining
 * @property {string} Th-T Binding
 * @property {string} FTIR peaks
 * @property {string} Proteostat binding
 * @property {string} WALTZ
 * @property {string} TANGO
 * @property {string} PASTA parallel
 * @property {string} PASTA antiparallel
 * @property {string} FTIR Image
 * @property {string} TEM image
 * @property {string} THT image
 * @property {string} Reference
 * @property {string} CF Helix
 * @property {string} CF Strand
 * @property {string} Hydrophobicity
 * @property {string} Structures
 * @property {string} UniProt AC
 * @property {string} UniProt ID
 * @property {string} Position
 * @property {string} Mutant
 * @property {string} Class
 * @property {string} Model
 * @property {string} Stability
 * @property {string} Backbone Hbond
 * @property {string} Electrostatic kon
 * @property {string} Electrostatics
 * @property {string} Entropy Mainchain
 * @property {string} Entropy Sidechain
 * @property {string} Sidechain Hbond
 * @property {string} Solvation Hydrophobic
 * @property {string} Solvation Polar
 * @property {string} VdW
 * @property {string} VdW Clashes
 */
import db from './waltzdb_export.json';

const headers = [
  {
    key: 'sequence',
    label: 'Sequence',
  },
  {
    key: 'score',
    label: 'Blosum62 Score',
  },
  {
    key: 'class',
    label: 'Class',
  },
  {
    key: 'downloadUrl',
    label: 'Download Model',
  },
  {
    key: 'tem',
    label: 'Tem Staining',
  },
  {
    label: 'Th-T Binding',
    key: 'tht',
  },
  {
    label: 'FTIR peaks',
    key: 'ftirPeaks',
  },
  {
    label: 'Proteostat binding',
    key: 'proteostatBinding',
  },
  {
    label: 'WALTZ',
    key: 'waltz',
  },
  {
    label: 'TANGO',
    key: 'tango',
  },
  {
    label: 'PASTA parallel',
    key: 'pastaParallel',
  },
  {
    label: 'PASTA antiparallel',
    key: 'pastaAntiparallel',
  },
  {
    label: 'Hydrophobicity',
    key: 'hydrophobicity',
  },
  {
    label: 'CF Helix',
    key: 'cfHelix',
  },
  {
    label: 'CF Strand',
    key: 'cfStrand',
  },
];

const SimilarEntries = ({ matches, segment }) => {
  const data = db
    .filter(({ Sequence }) =>
      matches.some((match) => match.sequence === Sequence)
    )
    .map((entry) => {
      const res = {};
      Object.keys(entry).forEach((key) => {
        res[key] = stripTags(entry[key]);
        res[`_${key}`] = entry[key];
      });
      return res;
    })
    .map(
      ({
        Sequence: sequence,
        _Model: model,
        Classification,
        'TEM Staining': tem,
        'Th-T Binding': tht,
        'FTIR peaks': ftirPeaks,
        'Proteostat binding': proteostatBinding,
        WALTZ: waltz,
        TANGO: tango,
        'PASTA parallel': pastaParallel,
        'PASTA antiparallel': pastaAntiparallel,
        Hydrophobicity: hydrophobicity,
        'CF Helix': cfHelix,
        'CF Strand': cfStrand,
      }) => {
        const downloadUrl = model?.match(/a\shref="([^"]+)"/)?.[1];

        return {
          sequence: (
            <Link
              href={`http://waltzdb.switchlab.org/sequences/${sequence}`}
              target="_blank"
            >
              {sequence}
              <IconExternalLink />
            </Link>
          ),
          _sequence: sequence,
          downloadUrl: downloadUrl && (
            <Link href={downloadUrl} target="_blank">
              {`Download ${sequence}.pdb`}
            </Link>
          ),
          class: Classification,
          tem,
          tht,
          ftirPeaks,
          proteostatBinding,
          waltz,
          tango,
          pastaParallel,
          pastaAntiparallel,
          hydrophobicity,
          cfHelix,
          cfStrand,
          score: matches.find((match) => match.sequence === sequence).score,
        };
      }
    )
    .sort((b, a) => a.score - b.score);

  const hasMatches = useMemo(() => !!data.length, [data]);

  const [enlarged, setEnlarged] = useState(false);

  useEffect(() => {
    if (enlarged) {
      document.body.classList.add('details__actions__enlarge-body');
    }

    return () => {
      document.body.classList.remove('details__actions__enlarge-body');
    };
  }, [enlarged]);

  const downloadTable = useCallback(() => {
    const table = [
      headers
        .filter(({ key }) => key !== "downloadUrl")
        .map(({ label }) => label)
        .join(","),
      ...data.map((row) =>
        headers
          .filter(({ key }) => key !== "downloadUrl")
          .map(({ key }) => (key === "sequence" ? row._sequence : row[key]))
          .join(",")
      ),
      '',
    ].join("\n");

    downloadAsFile(`${segment}-similar-entries.csv`, table);
  }, [data, segment]);

  return (
    <>
      <div className="details__side-title">
        <Typography variant="h5">
          Similar Entries in WaltzDB
          <button
            className="details__side-download"
            onClick={ downloadTable }
            title="Download as CSV"
            type="button"
          >
            <IconDownload />
          </button>
        </Typography>
      </div>

      {hasMatches && (
        <div
          className={classnames("details__actions__enlarge-overlay", {
            "details__actions__enlarge-overlay--show": enlarged,
          })}
          label="Close"
          onClick={() => setEnlarged(false)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setEnlarged(false);
            }
          }}
          role="button"
          tabIndex={0}
        >
          <button
            className="details__actions__enlarge-close"
            onClick={() => setEnlarged(false)}
            type="button"
          >
            X
          </button>
        </div>
      )}

      <div
        className={classnames("details__actions__table-outer", {
          "details__actions__table-outer--enlarged": enlarged,
        })}
      >
        {hasMatches && (
          <button
            className="details__actions__enlarge"
            onClick={() => setEnlarged(!enlarged)}
            type="button"
          >
            <span>Expand</span>
          </button>
        )}
        <div className="details__actions__table">
          {hasMatches && <Table headers={headers} data={data} />}
        </div>
      </div>
    </>
  );
};

SimilarEntries.propTypes = {
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      sequence: PropTypes.string.isRequired,
    })
  ).isRequired,
  segment: PropTypes.string.isRequired,
};

export default SimilarEntries;
