import React from 'react';
import { Link } from '@material-ui/core';
import Table from 'frontend-common/src/Components/Table';
import { useHistory } from 'react-router-dom';

export default function SampleSequences() {
  const history = useHistory();
  const sequences = [
    {
      sequence: 'Curli major subunit',
      jobId: 'a9947d5f646821ba'
    },
    {
      sequence: 'Medin',
      jobId: 'd66af7dc2c02eb12'
    },
    {
      sequence: 'Tau aggregation prone region',
      jobId: 'dcf8b6f5ffdc9fc2'
    }
  ];

  return (
    <>
      <h4>Sample sequences</h4>
      <Table
        headers={[
          {
            key: 'sequence',
            label: 'Sequence'
          },
          {
            key: 'results',
            label: 'Results'
          }
        ]}
        data={sequences.map(({ sequence, jobId }) => ({
          sequence,
          results: (
            <Link
              href={`/dashboard/${jobId}`}
              target='_blank'
              rel='noreferrer'
              onClick={(e) => {
                history.push(`/dashboard/${jobId}`);
                e.preventDefault();
              }}>
              View results
            </Link>
          )
        }))}
      />
    </>
  );
}
