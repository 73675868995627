import React from 'react';
import Markdown from 'react-markdown';

import Viewport from 'frontend-common/src/Components/Viewport';

import help from '../content/screens/help.json';

export default () => (
  <Viewport
    title={ help.title }
    dashboardBtn
    logoutBtn
    className="About__wrapper"
  >
    <Markdown>{ help.body }</Markdown>
  </Viewport>
);
