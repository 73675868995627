import PropTypes from 'prop-types';
import React, { memo } from 'react';
import cn from 'classnames';

import {
  Table as MTable,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

const Table = ({ data, placeholder, headers, classNames }) => (
  <Paper className={cn('JobList__wrapper', classNames.wrapper)}>
    <MTable className={classNames.root}>
      <TableHead className={cn('JobList__header', classNames.header)}>
        <TableRow>
          {headers.map(({ key, label, className }) => (
            <TableCell
              key={key}
              className={cn(
                'JobList__header-cell',
                classNames.cells,
                className
              )}>
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className={classNames.body}>
        {data.length ? (
          data.map((datum, i) => (
            <TableRow className={cn('JobList__row', classNames.rows)}>
              {headers.map(({ key, className }) => (
                <TableCell
                  className={cn(
                    i === 0 ? 'JobList__row__titleCell' : undefined,
                    classNames.cells,
                    className
                  )}>
                  {datum[key]}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell align='center' colSpan={6}>
              {placeholder}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </MTable>
  </Paper>
);

Table.propTypes = {
  placeholder: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      /**
       * The class to apply to the current ***cell***.
       */
      className: PropTypes.string
    })
  ).isRequired,
  classNames: PropTypes.shape({
    /**
     * The class to apply to the wrapper element.
     * This is the element that wraps the entire table.
     */
    wrapper: PropTypes.string,
    /**
     * The class to apply to the table element itself.
     */
    root: PropTypes.string,
    /**
     * The class to apply to the table header.
     * This is the element that contains the table headers.
     */
    header: PropTypes.string,
    /**
     * The class to apply to the table body.
     */
    body: PropTypes.string,
    /**
     * The class to apply to the table cells.
     */
    rows: PropTypes.string,
    /**
     * The class to apply to the table cells.
     */
    cells: PropTypes.string
  })
};

Table.defaultProps = {
  placeholder: 'You have not submitted any jobs yet.',
  classNames: {}
};

// The configurator provides us the basic login and sign up screens.
export default memo(Table);
