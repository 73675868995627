import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';

import { Link, Typography } from '@material-ui/core';

import useJobs from 'frontend-common/src/Hooks/useJobs';

import NGL from 'frontend-common/src/Components/NGL';
import Details from 'frontend-common/src/Screens/Details';
import IconDownload from 'frontend-common/src/Components/Icon/Download';
import downloadFileFromBucket from 'frontend-common/src/utils/downloadFileFromBucket';

import DetailsArea from '../Components/Details/Area';
import getContainingAmyloid from '../utils/getContainingAmyloid';
import SimilarEntries from '../Components/Details/SimilarEntries';

let latest = null;

export default () => {
  const { id } = useParams();
  const jobs = useJobs();
  const [job, setJob] = useState();
  const [profile, setProfile] = useState();
  const [selected, setSelected] = useState();
  const [selectedSegment, setSelectedSegment] = useState();
  const [modelData, setModelData] = useState();
  const [hasAmyloid, setHasAmyloid] = useState(false);
  const [isBelowThreshold, setIsBelowThreshold] = useState(false);
  const [links, setLinks] = useState(null);

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }

      const newJob = await jobs.get(id);
      if (job && job.JobId === id) {
        return;
      }

      setJob(newJob);
      // Reset related data when job changes
      setProfile();
      setSelected();
    })();
  }, [id, job, jobs]);

  useEffect(() => {
    (async () => {
      if (job) {
        const profileName = /^profile\.csv$/;
        const resultsKey = job.Results.find((file) => profileName.test(file.key))?.key;
        if (resultsKey) {
          const jobKey = `${job.JobId}/${resultsKey}`;

          const { UserId } = job;

          const contents = await jobs.readFile({
            Key: jobKey,
            bucket: 'results',
            contentType: 'text/csv',
            level: 'private',
            identityId: UserId
          });

          if (!contents) return;
          setProfile(
            contents
              .split(/\n/)
              .map((line) => {
                const [aminoacid, value, categorization] = line.split(/\t/);
                return {
                  aminoacid,
                  categorization,
                  value: Number(value)
                };
              })
              .filter((entry) => entry.aminoacid)
          );
        }
      }
    })();
  }, [job, jobs]);

  useEffect(() => {
    setSelectedSegment(
      selected && profile && selected.map((idx) => profile[idx].aminoacid).join('')
    );
  }, [profile, selected]);

  useEffect(() => {
    setHasAmyloid(
      !!profile && profile.some((entry) => entry.categorization === 'Amyloid')
    );
  }, [profile]);

  useEffect(() => {
    (async () => {
      if (selectedSegment) {
        const pdbContents = await jobs.readFile({
          Key: `output_models/${selectedSegment}.pdb`,
          bucket: 'results',
          contentType: 'text/plain',
          level: 'public'
        });

        if (pdbContents === null) {
          setIsBelowThreshold(true);
          setModelData();
          return;
        }

        setIsBelowThreshold(false);
        setModelData([
          {
            file: new window.Blob([pdbContents], { type: 'text/plain' }),
            config: {
              ext: 'pdb'
            }
          }
        ]);
      } else {
        setModelData();
      }
    })();
  }, [jobs, selectedSegment]);

  useEffect(() => {
    // Trick to avoid race-conditions from the below await, which could take too long and `setLinks`
    // for a previous selection.
    latest = {};
    const here = latest;

    (async () => {
      const main = job && job.Results.find((file) => file.key.endsWith(".zip"))?.url;
      const segment =
        selectedSegment &&
        !isBelowThreshold &&
        (await downloadFileFromBucket(
          "results",
          `output_models/${selectedSegment}.pdb`
        ));

      if (here !== latest) {
        return;
      }

      setLinks({ main, segment });
    })();
  }, [job, isBelowThreshold, selectedSegment]);

  return (
    <Details className="details__main-wrapper">
      <div className="details__columns">
        <div className="details__column">
          <div className="details__sequence">
            {!!profile &&
              profile.map((entry, idx) => (
                <button
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${idx}-${entry.aminoacid}`}
                  className={classnames("details__aminoacid", {
                    "details__aminoacid--amyloid":
                      entry.categorization === "Amyloid",
                    "details__aminoacid--selected":
                      selected && selected.includes(idx),
                  })}
                  onClick={() => {
                    // if (entry.categorization === "Amyloid") {
                      const ids = getContainingAmyloid(profile, idx);
                      setSelected(ids);
                    // }
                  }}
                  type="button"
                >
                  {entry.aminoacid}
                </button>
              ))}
          </div>

          <NGL
            data={modelData}
            placeholder={
              // eslint-disable-next-line no-nested-ternary
              isBelowThreshold
                ? "This hexapeptide scored below the threshold."
                : hasAmyloid
                ? "Green aminoacids are predicted as part of an amyloid structure, press them to see their interactive model."
                : "The processed sequence does not contain amyloid structures."
            }
          />

          {!!profile && <DetailsArea job={job} profile={profile} />}
        </div>

        <div className="details__column">
          <div className="details__side-title">
            <Typography variant="h5">Actions</Typography>
          </div>

          <ul className="details__actions">
            {!!links?.main && (
              <li className="details__side-link">
                <Link href={links.main}>
                  Download job results
                  <IconDownload />
                </Link>
              </li>
            )}

            {!!links?.segment && (
              <li className="details__side-link">
                <Link href={links.segment}>
                  {`Download ${selectedSegment}.pdb`}
                  <IconDownload />
                </Link>
              </li>
            )}
          </ul>

          {!!selectedSegment &&
            job?.Processed.SimilarityMatches?.[selectedSegment] && (
              <SimilarEntries
                matches={job.Processed.SimilarityMatches[selectedSegment]}
                segment={selectedSegment}
              />
            )}
        </div>
      </div>
    </Details>
  );
};
