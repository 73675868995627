import Amplify, { Storage } from '../lib/Amplify';

const downloadFileFromBucket = (bucket, path) => {
  Amplify.withBucket(bucket);

  return Storage.get(path, {
    expires: 86400,
    level: 'public',
  });
};

export default downloadFileFromBucket;
