export default (profile, idx) => {
  const ids = [ idx ];

  let remaining = 5;
  let nextIdx = idx + 1;
  let prevIdx = idx - 1;

  while (remaining && profile[nextIdx] /* && profile[nextIdx].categorization === 'Amyloid' */) {
    ids.push(nextIdx);
    nextIdx++;
    remaining--;
  }

  while (remaining && profile[prevIdx] /* && profile[prevIdx].categorization === 'Amyloid' */) {
    ids.unshift(prevIdx);
    prevIdx--;
    remaining--;
  }

  return ids;
};
